import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
import IconBase, { IconProps } from '../IconBase';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconError24 = (baseProps: IconProps, ref: Ref<SVGSVGElement>) => {
  return <IconBase {...baseProps}>
        {({
      title,
      titleId,
      ...props
    }: Partial<React.SVGProps<SVGSVGElement>> & SVGRProps) => <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M12 1.74219L12.6922 2.9367L23.1922 21.0567L23.8882 22.2578H22.5H1.5H0.111816L0.807818 21.0567L11.3078 2.9367L12 1.74219ZM2.88819 20.6578H21.1118L12 4.93341L2.88819 20.6578ZM12.8 9.9778V10.7778L12.8 14.6178V15.4178H11.2V14.6178V10.7778V9.9778L12.8 9.9778ZM11.2 17.1778H12H12.048H12.848V18.7778H12.048H12H11.2V17.1778Z" fill="currentColor" /></svg>}
      </IconBase>;
}; // This explicit type declaration prevents TypeScript from expanding IconProps into a huge Pick type
const ForwardRef: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>> = React.forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  return IconError24(props, ref);
});
export default ForwardRef;